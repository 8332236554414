
import {
  reactive,
  ref,
  SetupContext,
  defineComponent,
  onMounted,
} from "vue";
import {
  CoreDayByExaminer,
  CoreDayByExaminerDto,
  CoreDayStatusEnum,
  CoreDayRequestTimeSlotEnum,
  SaveCoreDayByExaminerRequest,
  SearchCoreDayByExaminerRequest,
} from "@/api-client/client";
import { _Client } from "@/api-client";
import { Form, Switch, message } from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import { Item } from "ant-design-vue/lib/menu";

const useForm = Form.useForm;

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    width: '13%',
    customRender: (dateText: any) => {
      const date = new Date(dateText.value);
      const options = { weekday: 'short' }; // 星期缩写
      const weekdayAbbreviation = date.toLocaleDateString('en-US', options);
      return `${dateText.value} (${weekdayAbbreviation})`;
    }
  },
  {
    title: 'Time Slot',
    dataIndex: 'timeslot',
    width: '13%',
  },
  {
    title: 'Non Core Day',
    dataIndex: 'nonCoreDay',
    width: '10%',
  },
  {
    title: 'Core Day',
    dataIndex: 'coreDay',
    width: '10%',
  },
  {
    title: 'Remark',
    dataIndex: 'remark',
    width: '15%',
  },
];

const checkboxValue = ref([]);

const today = new Date();
const currentYear = today.getFullYear();
const currentMonth = today.getMonth() + 1;
const coreDayTimeSlot = CoreDayRequestTimeSlotEnum;
const coreDayStatus = CoreDayStatusEnum;
const coreDayStatusList = [CoreDayStatusEnum.CoreDay, CoreDayStatusEnum.AnnualLeave, CoreDayStatusEnum.Other];
const nonCoreDayStatusList = [CoreDayStatusEnum.Training, CoreDayStatusEnum.Standardisation, CoreDayStatusEnum.OffDueToWeekendCoreDay];

interface DataItem {
  date: string;
  timeslot: CoreDayRequestTimeSlotEnum[] | undefined;
  nonCoreDay: CoreDayStatusEnum | undefined;
  coreDay: CoreDayStatusEnum | undefined;
  remark: string | undefined;
  coreDayStr: string | undefined;
  nonCoreDayStr: string | undefined;
  coreDayNotSelected: boolean | undefined,
}

const data: DataItem[] = [];

export default defineComponent({
  props: {

    modifyExaminerId: { type: String },
    modifyExaminerName: { type: String }
  },
  setup(props: { [key: string]: any }, context: SetupContext) {
    const saveLoading = ref<boolean>(false);
    const formLayout = { labelCol: { span: 6 }, wrapperCol: { span: 14 } };
    const searchYear = ref<number>(currentYear);
    const searchMonth = ref<number>(currentMonth);
    const tableLoading = ref<boolean>(false)
    function close(refresh: boolean = false) {
      context.emit("close", refresh);
    }
    const searchParames = ref<SearchCoreDayByExaminerRequest>();
    const tableSource = ref<CoreDayByExaminerDto[]>()
    const dataSource = ref<DataItem[]>([])
    const examinerName = props.modifyExaminerName;

    /// 获取列表方法
    async function getList() {
      dataSource.value = [];
      let dates: string[] = [];
      try {
        tableLoading.value = true
        searchParames.value = new SearchCoreDayByExaminerRequest();
        searchParames.value.examinerId = props.modifyExaminerId;
        searchParames.value.codyDayDate = new Date(searchYear.value + '-' + (searchMonth.value).toString().padStart(2, '0') + '-01');
        tableSource.value = await _Client.coreDayManagementClient.getCoreDayListByExaminer(searchParames.value!);
        const queryYear = searchYear.value;
        const queryMonth = searchMonth.value;
        // 当前月份的第一天 (月份方法是从0开始的  所以queryMonth要-1)
        const firstDayOfMonth = new Date(queryYear, queryMonth - 1, 1);

        // 下个月的第一天
        const firstDayOfNextMonth = new Date(queryYear, queryMonth, 1);

        // 循环从当前月的第一天开始，直到下个月的第一天之前
        for (let d = new Date(firstDayOfMonth); d < firstDayOfNextMonth; d.setDate(d.getDate() + 1)) {
          // 将日期格式化并添加到数组中，例如："2023-04-01"
          dates.push(d.getFullYear() + '-' + ('' + (d.getMonth() + 1)).padStart(2, '0') + '-' + ("" + d.getDate()).padStart(2, '0'));
        }
        for (let i = 0; i < dates.length; i++) {
          let itemTable = tableSource.value!.find(item => item.date.getTime() === new Date(dates[i]).getTime());
          itemTable = CoreDayByExaminerDto.fromJS(itemTable);
          
          let itemNonCoreDayStatus = nonCoreDayStatusList.find(item => item === itemTable!.coreDayStatus);
          let itemCoreDayStatus = coreDayStatusList.find(item => item === itemTable!.coreDayStatus);
          dataSource.value.push({
            date: dates[i],
            timeslot: itemTable.coreDayRequestTimeSlot == undefined ? undefined : [itemTable.coreDayRequestTimeSlot],
            nonCoreDay: itemNonCoreDayStatus,
            coreDay: itemCoreDayStatus,
            remark: itemTable.remark,
            nonCoreDayStr: GetCoreDayStatusStr(itemNonCoreDayStatus),
            coreDayStr: GetCoreDayStatusStr(itemCoreDayStatus),
            coreDayNotSelected: false,
          });
        }
      }
      finally {
        tableLoading.value = false
      }
    }
    async function ModifyCoreDay() {
      saveLoading.value = true;
      try {
        let request = new SaveCoreDayByExaminerRequest();
        let coreDayByExaminerList: CoreDayByExaminer[] = [];

        dataSource.value.forEach((itemTable: DataItem) => {
          let itemDate = new CoreDayByExaminer();
          itemDate.date = new Date(itemTable.date);
          itemDate.coreDayRequestTimeSlot = (itemTable.timeslot == undefined || itemTable.timeslot.length == 0) ? undefined : itemTable.timeslot[0];
          itemDate.nonCoreDay = GetCoreDayStatusEnum(itemTable.nonCoreDayStr);
          itemDate.coreDay = GetCoreDayStatusEnum(itemTable.coreDayStr);
          itemDate.remark = itemTable.remark;
          itemDate.examinerId = props.modifyExaminerId;
          if (itemDate.coreDayRequestTimeSlot != undefined && !itemDate.nonCoreDay && !itemDate.coreDay) {
            itemTable.coreDayNotSelected = true;
          }
          coreDayByExaminerList.push(itemDate);
        });

        if (dataSource.value.some(x => x.coreDayNotSelected === true)) {
          saveLoading.value = false
          return;
        }

        request.requestList = coreDayByExaminerList;
        await _Client.coreDayManagementClient.saveCoreDayByExaminer(request);
        message.success("success");
        close(true);
      }
      finally {
        saveLoading.value = false
      }
    }

    const changeSearchParames = async () => {
      await getList()
    };
    async function resetSearchParams() {
      searchYear.value = currentYear;
      searchMonth.value = currentMonth;
      searchParames.value = new SearchCoreDayByExaminerRequest({
        examinerId: props.modifyExaminerId,
        codyDayDate: new Date(searchYear.value + '-' + (searchMonth.value).toString().padStart(2, '0') + '-01')
      })
      await getList()
    }
    function GetCoreDayStatusStr(coreDayStatus: CoreDayStatusEnum | undefined) {
      switch (coreDayStatus) {
        case CoreDayStatusEnum.CoreDay:
          return "Core Day";
        case CoreDayStatusEnum.AnnualLeave:
          return "Annual Leave";
        case CoreDayStatusEnum.OffDueToWeekendCoreDay:
          return "Off due to weekend core day";
        case CoreDayStatusEnum.Other:
          return "Other";
        case CoreDayStatusEnum.Standardisation:
          return "Standardisation";
        case CoreDayStatusEnum.Training:
          return "Training";
        default:
          return '';
      }
    }
    function GetCoreDayStatusEnum(coreDayStatus: string | undefined) {
      switch (coreDayStatus) {
        case "Core Day":
          return CoreDayStatusEnum.CoreDay;
        case "Annual Leave":
          return CoreDayStatusEnum.AnnualLeave;
        case "Off due to weekend core day":
          return CoreDayStatusEnum.OffDueToWeekendCoreDay;
        case "Other":
          return CoreDayStatusEnum.Other;
        case "Standardisation":
          return CoreDayStatusEnum.Standardisation;
        case "Training":
          return CoreDayStatusEnum.Training;
        default:
          return undefined;
      }
    }
    function handleChangeTimeSlot(record: DataItem) {
      if (record.timeslot == undefined || record.timeslot!.length == 0) {
        record.coreDayStr = '';
        record.nonCoreDayStr = '';
        record.coreDayNotSelected = false;
      }
    }
    function handleSelectChange(record: DataItem) {
      if (record.nonCoreDayStr || record.coreDayStr) {
        record.coreDayNotSelected = false; // 设置为 false，表示已经选择了
      }
    }
    onMounted(async () => {
      await getList()
    })

    return {
      formLayout,
      tableLoading,
      close,
      columns,
      editingKey: '',
      tableSource,
      dataSource,
      coreDayTimeSlot,
      checkboxValue,
      resetSearchParams,
      changeSearchParames,
      coreDayStatus,
      examinerName,
      ModifyCoreDay,
      handleChangeTimeSlot,
      searchYear,
      searchMonth,
      currentYear,
      saveLoading,
      handleSelectChange
    };
  }
});
