
import {
  onMounted,
  PropType,
  reactive,
  ref,
  SetupContext,
  toRaw,
  defineComponent,
  toRefs,
} from "vue";
import { CreateExaminerHubCmdForm } from "@/api-client/modal";
import { AddCoreDayByExaminerRequest, CoreDayStatusEnum, CoreDayRequestTimeSlotEnum } from "@/api-client/client";
import { validateHubCapacity } from "@/utils/validate";
import { _Client } from "@/api-client";
import { message, Form, DatePicker, Button, Modal } from "ant-design-vue";
import dayjs from 'dayjs';
import { createVNode } from 'vue'; // 引入 h 函数

const useForm = Form.useForm;
interface AddExaminerModel {
  examinerId: string | undefined;
  examinerName: string | undefined;
  examinerNo: string | undefined;
}
export default defineComponent({
  props: {
    examinerList: {
      type: Array as PropType<AddExaminerModel[]>,
      default: () => [],
    },
  },
  setup(props: { [key: string]: any }, context: SetupContext) {
    const examinerList = props.examinerList;
    const remark = ref<string>();
    const coreDayType = ref<string>();
    const nonCoreDayStr = ref<string>();
    const coreDayStr = ref<string>();
    const timeSlot = ref<CoreDayRequestTimeSlotEnum>();
    function close(refresh: boolean = false) {
      context.emit("close", refresh);
    }
    const saveLoading = ref<boolean>(false);
    const dates = ref<string[]>([]);
    const showDatePicker = ref(false);
    const disabledDate = (val: any) => {
      if (!val) {
        return false;
      }

      //// 历史日期不可选
      if (dayjs().isAfter(val, 'day')) {
        return true;
      }

      return false;
    }

    const selectDate = (val: any) => {
      const date = val.format("YYYY-MM-DD")
      return dates.value.some(x => x == date);
    }
    const dateChange = (value: any) => {
      var index = dates.value.findIndex((x) => x == value.format("YYYY-MM-DD"))
      if (index == -1) {
        dates.value.push(value.format("YYYY-MM-DD"));
      }
      else {
        dates.value.splice(index, 1)
      }

    };

    // 处理关闭标签的事件
    const handleClose = (
      tagToRemove: { examinerId: string; examinerName: string },
      index: number
    ) => {
      // 从数组中移除标签
      examinerList.splice(index, 1);
      console.log(examinerList);
    };
    async function AddCoreDay() {
      saveLoading.value = true;
      try {
        if ((nonCoreDayStr.value == '' || nonCoreDayStr.value == undefined) && (coreDayStr.value == '' || coreDayStr.value == undefined)) {
          message.warning('Please select Core Day Type.')
          return;
        }
        if (dates.value.length == 0) {
          message.warning('Please select Date.')
          return;
        }
        if (timeSlot.value == undefined) {
          message.warning('Please select Time Slot.')
          return;
        }
        let request = new AddCoreDayByExaminerRequest();
        let requestList: AddCoreDayByExaminerRequest[] = [];
        request.init({
          examinerIdList: examinerList.map((examiner: AddExaminerModel) => examiner.examinerId),
          coreDayStatus: coreDayStr.value == "" || coreDayStr.value == undefined
            ? GetCoreDayStatusEnum(nonCoreDayStr.value)
            : GetCoreDayStatusEnum(coreDayStr.value),
          dateList: dates.value.map((date) => new Date(date)),
          coreDayTimeSlot: timeSlot.value,
          remark: remark.value
        });
        requestList.push(request);
        let result = await _Client.coreDayManagementClient.addCoreDayByExaminer(requestList[0]);
        if (result.isSuccess) {
          message.success("success");
          close(true);
        } else {

          if (result.hasDateExaminerList != null && result.hasDateExaminerList.length > 0) {
            let warning = "";
            result.hasDateExaminerList.forEach((hasDateExaminer) => {
              let repeatExaminerList = examinerList.filter((x: AddExaminerModel) => hasDateExaminer.examinerIdList?.includes(x.examinerId!));
              let date = hasDateExaminer.date.getFullYear() + '-' +
                ('0' + (hasDateExaminer.date.getMonth() + 1)).slice(-2) + '-' +
                ('0' + hasDateExaminer.date.getDate()).slice(-2);
              warning += repeatExaminerList.map((item: AddExaminerModel) => item.examinerNo).join(',') + " The examiner has already been scheduled " + date + ".<br/>";
            });
            Modal.warning({
              title: "",
              // 使用 function(h) 渲染 HTML 内容
              content: () => {
                return createVNode('div', { innerHTML: warning });
              },

              okText: "Confirm",
              width: 600 // 设置 Modal 的宽度为 600 像素
            })
            //message.warning(warning);
          }
          else {
            message.error("error");
          }
        }
      }
      finally {
        saveLoading.value = false;

      }
    }

    function GetCoreDayStatusEnum(coreDayStatus: string | undefined) {
      switch (coreDayStatus) {
        case "Core Day":
          return CoreDayStatusEnum.CoreDay;
        case "Annual Leave":
          return CoreDayStatusEnum.AnnualLeave;
        case "Off due to weekend core day":
          return CoreDayStatusEnum.OffDueToWeekendCoreDay;
        case "Other":
          return CoreDayStatusEnum.Other;
        case "Standardisation":
          return CoreDayStatusEnum.Standardisation;
        case "Training":
          return CoreDayStatusEnum.Training;
        default:
          return undefined;
      }
    }

    return {
      close,
      examinerList,
      handleClose,
      coreDayType,
      nonCoreDayStr,
      coreDayStr,
      AddCoreDay,
      timeSlot,
      remark,
      dates,
      showDatePicker,
      dateChange,
      disabledDate,
      selectDate,
      saveLoading
    };
  },
  watch: {
    // 监听 CoreDayType 的变化，以便在条件成立时清空 <a-select>
    coreDayType(newVal) {
      if (newVal === "Core Day") {
        this.nonCoreDayStr = ""; // 条件成立时清空 <a-select>
      }
      if (newVal === "Non-Core Day") {
        this.coreDayStr = ""; // 条件成立时清空 <a-select>
      }
    },
    coreDayStr(newVal) {
      if (newVal != "Other") {
        this.remark = "";
      }
    },
  },
});
